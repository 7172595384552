import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DownloadApp from '../components/DownloadApp';
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO title="Jenny's testimonial" />

    <div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-split-content full-width">
            <div className="content-width-medium">
              <h1 className="h3">"the program is already giving me a better attitude that’s making me take even better care of myself"</h1>
              <div className="div-block"><img width={74} src="/images/5ddc498444a1cc7c9762354a_26-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                <div>
                  <div className="handwriting">Jenny</div>
                  <div>Wise Guide User</div>
                </div>
              </div>
            </div>
            <div className="content-width-large"><img src="/images/5ddc481215f66c0b0a9eb10e_yoga-meditating-zen-like-women-one-woman-only-healthy-lifestyle-exercising-relaxation-african_t20_8lZ0eZ.jpg" alt="" sizes="(max-width: 479px) 95vw, (max-width: 767px) 90vw, (max-width: 991px) 612px, 46vw" srcSet="/images/5ddc481215f66c0b0a9eb10e_yoga-meditating-zen-like-women-one-woman-only-healthy-lifestyle-exercising-relaxation-african_t20_8lZ0eZ-p-800.jpeg 800w, /images/5ddc481215f66c0b0a9eb10e_yoga-meditating-zen-like-women-one-woman-only-healthy-lifestyle-exercising-relaxation-african_t20_8lZ0eZ.jpg 1047w" /></div>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="w-layout-grid sidebar-layout case-study-layout">
            <div className="sidebar">
              <div className="card shadow-small">
                <ul className="w-list-unstyled">
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Name</h6>
                    <div>Jenny</div>
                  </li>
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Programs</h6>
                    <div>Manage weight, Overcome Fear</div>
                  </li>
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Results</h6>
                    <div>More self confidence and got her weight under control </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="content-width-large">
                <div className="article w-richtext">
                  <p>Jenny lives in Florida and works in social services. “I used to be afraid of life,” she begins. “Now I embrace it.” Jenny explains where her fear originated. “I had some very difficult experiences in early life and young adulthood which created problems with my self-image and confidence. At college, I was afraid to speak up in class. My fear was holding me back so I looked for something to help me.”</p>
                  <p>Jenny came across Effective Learning Systems at a Barnes and Noble store. “I began using the Effective Learning programs almost twenty years ago and noticed a difference in my behavior within a few weeks,” she shares. “I’ve been able to overcome almost all of my negative “programming” and negative self-talk. I was even able to give a presentation about the benefits of guided imagery and self-hypnosis to 150 people. This from a woman who couldn’t speak up in class.”</p>
                  <p>Part of the appeal for Jenny is the nature of the programs. “It’s both spiritual and science-based so the part of me that wants to nurture a spiritual perspective appreciates the message that we are all part of this grand experience called life. The other part of me that is skeptical of things that sound “too good to be true” responds to the science-based explanations about breathing.”</p>
                  <p>When asked about the process, Jenny explains, “I am led into a deeply relaxed state and guided to experience some beautiful images. If I use a CD at night, it facilitates my falling into a deep restful sleep. I often have uplifting dreams as well. If I use a CD during the day, it makes me feel refreshed and as though I’ve had a long nap. I wouldn’t say the programs are a “magic bullet” but I think of their use as an exercise regime done to get in shape. You will want to use them on a long-term basis for continued inspiration for your goal.”</p>
                  <p>Jenny suggests Weight Loss/Stay Slim to help me lose weight and Super Strength Self Esteem/Improve Your Self Image. She uses them a few times every week, sometimes as a sleep aide. “I just ordered four new titles,” she adds. “I’ve used products from at least three other companies of a similar nature. Effective Learning Systems are my favorite. I’ve been using Loving Your Body for about two weeks now and I really like it. I’m not thrilled about being close to 50 and have been a bit down about the natural effects of aging on my body. I believe the program is already giving me a better attitude that’s making me take even better care of myself. I will continue to recommend Effective Learning Systems for years to come.”</p>
                  <p>‍</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-justify-center">
            <div className="section-title-wide">
              <h3 className="h2 no-bottom-space">More success stories</h3>
            </div>
          </div>
          <div className="w-dyn-list">
            <div className="case-study-cards-grid w-dyn-items">
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc49d9315b303c82a6af7b_15-People%20Avatar-Man.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">The programs have enabled me to increase my focus on positive outcomes. The process of listening for 30 days seems to be an effective way to drive home points about positive approaches to problems</p><Link to="/dorsey/">Read their story</Link>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc4bc87dc9835069f5ccfc_18-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">The program explained how to eject negatives and to choose to be confident. I started by smiling at other people and of being loving towards them. </p><Link to="/annie-marie/">Read their story</Link>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc49cd315b3039bba6af51_13-People%20Avatar-Man.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">Marlin lost a staggering 70 pounds using The Wise Guide weight loss program</p><Link to="/marlin/">Read their story</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DownloadApp />
  </Layout>
);
